// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("E:\\projetos\\estacionamento-juveve\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contato-js": () => import("E:\\projetos\\estacionamento-juveve\\src\\pages\\contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-index-js": () => import("E:\\projetos\\estacionamento-juveve\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "E:\\projetos\\estacionamento-juveve\\.cache\\data.json")

